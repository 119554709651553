<template>
    <div class="container" v-show="!showModel">
        <label for="model">Preview model</label>
        <br>
        <input id="model" type='file' v-on:change="handleFileChanged">
    </div>
    <model-viewer id="modelViewer" camera-controls touch-action="pan-y" autoplay ar shadow-intensity="1"
                  ar-modes="webxr scene-viewer quick-look" v-show="showModel" :ios-src="iosFile"
                  :src="file"></model-viewer>
</template>

<script>
import "@google/model-viewer";

export default {
    name: 'App',
    data() {
        return {
            file: null,
            iosFile: null,
            showModel: false,
        }
    },
    mounted() {
        let modelViewer = document.getElementById('modelViewer');
        modelViewer.addEventListener('load', () => {
            modelViewer.activateAR();
        })
    },
    methods: {
        handleFileChanged(e) {
            let file = e.target.files[0];
            let extension = this.getExtension(file.name);
            console.log(extension);
            if (extension === 'usdz') {
                this.iosFile = (window.URL || window.webkitURL).createObjectURL(file);
            } else {
                this.file = (window.URL || window.webkitURL).createObjectURL(file);
            }
            this.showModel = true;
        }, getExtension(filename) {
            let parts = filename.split('.');
            return parts[parts.length - 1];
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

model-viewer {
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 2em;
}

input {
    margin: auto;
}

html, body, #app {
    margin: 0;
    height: 100%;
}
</style>
